export const getObjektart = (immo) => {
  if (immo?.objektkategorie) {
    return JSON.stringify(immo.objektkategorie);
  }

  return null;
}

export const getHeizung = (immo) => {

  if (!immo.ausstattung.befeuerung) {
    return null;
  }

  const heizungen = [];
  if (immo.ausstattung.befeuerung.FERN) {
    heizungen.push("Fernwärme");
  }
  if (immo.ausstattung.befeuerung.GAS) {
    heizungen.push("Gas");
  }

  if (immo.ausstattung.befeuerung.LUFTWP) {
    heizungen.push("Wärmepumpe");
  }

  if (immo.ausstattung.befeuerung.SOLAR) {
    heizungen.push("Solar");
  }

  return heizungen.length > 0 ? heizungen.join(",") : null
};
