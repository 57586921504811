import React from 'react';

const RcCurrency = ( { value } ) => {
  if (!value) {
    return null;
  }

  return ( (new Intl.NumberFormat('de-DE').format(value) ) + " €");
};

export default RcCurrency;
